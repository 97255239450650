import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Events from "../components/Events/Events"

export default function EventsPage() {
  return (
    <Layout>
      <SEO title="Events" />
      <Events />
    </Layout>
  )
}
